import TournamentCard from "./components/TournamentCard";
import { firestore } from "../../firebase/config";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";

const Home = ({ user }) => {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      const now = Date.now();

      const yesterday = new Date(now - 86400000).toISOString().split("T")[0];

      const tournamentsRef = collection(firestore, "tournaments");

      const upcomingTournamentsQuery = query(
        tournamentsRef,
        where("endDate", ">=", yesterday),
        where("isTournamentPublic", "==", true)
      );

      try {
        const collection = await getDocs(upcomingTournamentsQuery);

        if (collection.docs) {
          const tournamentsList = collection.docs.map((doc) => ({
            id: doc.id, // Use the document ID if needed
            ...doc.data(),
          }));

          setTournaments(tournamentsList);
        } else {
          console.log("No tournaments!");
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchTournaments();
  }, []);

  const sortTournaments = (a, b) => {
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  };

  if (!tournaments) {
    return;
  }

  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex justify-center flex-wrap mt-4">
        {tournaments.sort(sortTournaments).map((tournament) => (
          <TournamentCard
            key={tournament.id}
            id={tournament.id}
            tournament={tournament}
            isUserOrganizer={tournament.organizerUids.includes(user?.uid)}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
