import {
  deleteDoc,
  deleteField,
  doc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "../../firebase/config";

const deleteSchedule = async (tournamentId, teams) => {
  const batch = writeBatch(firestore);
  const schedulesRef = doc(firestore, "schedules", tournamentId);
  const tournamentRef = doc(firestore, "tournaments", tournamentId);
  const teamsRef = doc(firestore, "teams", tournamentId);

  try {
    await updateDoc(tournamentRef, { schedule: deleteField() });

    await deleteDoc(schedulesRef);

    await updateDoc(tournamentRef, { scheduleType: deleteField() });
  } catch (error) {
    console.log(error);
    alert(error.message);
  }

  try {
    for (const teamId of Object.keys(teams)) {
      batch.update(teamsRef, {
        [`${teamId}.points`]: 0,
        [`${teamId}.wins`]: 0,
        [`${teamId}.draws`]: 0,
        [`${teamId}.losses`]: 0,
        [`${teamId}.goalsFor`]: 0,
        [`${teamId}.goalsAgainst`]: 0,
      });
    }
    await batch.commit();
  } catch (error) {
    console.log(error);
  }

  window.location.reload();
};

export default deleteSchedule;
