import { standingsBanner } from "../../../functions/banners";
import { useTournament } from "../TournamentContext";

const Standings = () => {
  const { tournament, teams } = useTournament();
  const { groups, schedule } = tournament;

  const sortTeams = (a, b) => {
    const teamA = teams[a];
    const teamB = teams[b];

    const teamAGoalDiff = teamA.goalsFor - teamA.goalsAgainst;
    const teamBGoalDiff = teamB.goalsFor - teamB.goalsAgainst;

    return (
      teamB.points - teamA.points ||
      teamB.wins - teamA.wins ||
      teamB.draws - teamA.draws ||
      teamA.losses - teamB.losses ||
      teamBGoalDiff - teamAGoalDiff ||
      teamB.goalsFor - teamA.goalsFor ||
      teamB.goalsAgainst - teamA.goalsAgainst ||
      0
    );
  };

  if (!schedule || !teams) {
    return;
  }

  return (
    <section className="w-full flex flex-col items-center mb-6">
      <div className="flex w-full flex-wrap justify-center gap-6 mt-8">
        {Object.entries(groups)
          .sort()
          .map(([groupId, teamIds]) => (
            <div
              key={groupId}
              className="w-full max-w-lg border rounded-xl shadow overflow-hidden h-full"
            >
              <div className={`${standingsBanner(groupId)} p-3 text-center`}>
                <h3 className="text-xl font-bold text-white">{`Group ${
                  Number(groupId) + 1
                }`}</h3>
              </div>
              <div className="p-1">
                <table className="w-full table-fixed">
                  <thead>
                    <tr className="text-sm sm:text-xs text-stone-500 font-extralight">
                      <th className="w-1/2">Team</th>
                      <th>Pl</th>
                      <th>W</th>
                      <th>D</th>
                      <th>L</th>
                      <th>GD</th>
                      <th>GF</th>
                      <th>GA</th>
                      <th>Pts</th>
                    </tr>
                  </thead>
                  <tbody className="text-center text-sm text-stone-700">
                    {teamIds.sort(sortTeams).map((teamId) => {
                      const matchesPlayed =
                        teams[teamId].wins +
                        teams[teamId].losses +
                        teams[teamId].draws;

                      const goalDiff =
                        teams[teamId].goalsFor - teams[teamId].goalsAgainst;

                      return (
                        <tr key={teamId}>
                          <td>{teams[teamId].name ?? ""}</td>
                          <td>{matchesPlayed ?? 0}</td>
                          <td>{teams[teamId].wins ?? 0}</td>
                          <td>{teams[teamId].draws ?? 0}</td>
                          <td>{teams[teamId].losses ?? 0}</td>
                          <td>{goalDiff ?? 0}</td>
                          <td>{teams[teamId].goalsFor ?? 0}</td>
                          <td>{teams[teamId].goalsAgainst ?? 0}</td>
                          <td className="font-bold">
                            {teams[teamId].points ?? 0}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default Standings;
