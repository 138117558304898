import React, { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/modals/Modal";
import generateSwissRound from "../../../../functions/generateSchedule/generateSwissRound";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import gameTime from "../../../../functions/gameTime";

const GenerateNextSwissRound = ({
  teams,
  matches,
  schedule,
  scheduleTimes,
  tournamentId,
}) => {
  const [isModalOpen, setisModalOpen] = useState(false);

  const toggleModal = () => {
    setisModalOpen(!isModalOpen);
  };

  const writeNextRound = async () => {
    const { newMatches, newRound } = generateSwissRound(
      teams,
      matches,
      Object.keys(schedule).length,
      schedule
    );

    const roundStartTime = gameTime(
      Object.keys(matches).length,
      scheduleTimes.startTimes[0],
      scheduleTimes.gameLength,
      scheduleTimes.interval,
      scheduleTimes.numberOfCourts
    );

    const startTimes = [...scheduleTimes.startTimes];

    startTimes.push(roundStartTime);

    if (newMatches === null) {
      return alert("All possible matchups have been played");
    }

    const tournamentRef = doc(firestore, "tournaments", tournamentId);
    const scheduleRef = doc(firestore, "schedules", tournamentId);

    const newSchedule = schedule;

    Object.assign(newSchedule, newRound);

    try {
      await updateDoc(tournamentRef, {
        schedule: newSchedule,
        "scheduleTimes.startTimes": startTimes,
      });
    } catch (error) {
      console.log(error);
    }

    try {
      await updateDoc(scheduleRef, newMatches);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-12">
      <Button onClick={toggleModal}>Next Round</Button>

      <Modal
        isOpen={isModalOpen}
        title="Confirm"
        subtitle="Previous round data will not be editable"
        onCancel={toggleModal}
        onConfirm={writeNextRound}
      ></Modal>
    </div>
  );
};

export default GenerateNextSwissRound;
