/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";
import { auth } from "../../firebase/config";
import { signOut } from "firebase/auth";
import { FaDiscord } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Sidebar = ({ sidebarOpen, toggleSidebar, isLoggedIn }) => {
  const { t } = useTranslation();
  const sidebarClass = sidebarOpen ? "translate-x-0" : "-translate-x-full";
  const backdropClass = sidebarOpen
    ? "opacity-50 pointer-events-auto"
    : "opacity-0 pointer-events-none";
  const linkClassName =
    "text-xl text-gray-700 font-medium transition-all duration-300 hover:text-gray-900 hover:translate-x-1";
  const contactClassname =
    "m-1 cursor-pointer text-gray-700 hover:text-gray-900 transition-colors duration-300";

  const logout = async () => {
    try {
      await signOut(auth);
      window.location.replace("https://shufflepolo.com/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <>
      {/* Sidebar */}
      <div
        className={`z-40 fixed top-0 left-0 ${sidebarClass} w-2/3 max-w-sm h-screen bg-white shadow-lg rounded-tr-lg rounded-br-lg transition-transform duration-500`}
      >
        <div className="flex flex-col h-full justify-between p-6">
          {/* Header */}
          <div className="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-center mb-8 shadow-sm">
            {t("component.sidebar.title")}
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col gap-6 mt-8">
            <Link to="/" onClick={toggleSidebar} className={linkClassName}>
              {t("component.sidebar.pagelink.home")}
            </Link>
            <Link
              to="/archive"
              onClick={toggleSidebar}
              className={linkClassName}
            >
              {"Archive"}
            </Link>
            {isLoggedIn && (
              <>
                <Link
                  to="/my-tournaments"
                  onClick={toggleSidebar}
                  className={linkClassName}
                >
                  {"My Tournaments"}
                </Link>

                <Link
                  to="/organize"
                  onClick={toggleSidebar}
                  className={linkClassName}
                >
                  {t("component.sidebar.pagelink.organize")}
                </Link>
              </>
            )}
            <Link
              to="/planner"
              onClick={toggleSidebar}
              className={linkClassName}
            >
              {t("component.sidebar.pagelink.planner")}
            </Link>
            {/* <Link to="/about" onClick={toggleSidebar} className={linkClassName}>
              {t("component.sidebar.pagelink.about")}
            </Link> */}
          </div>

          {/* Footer Section */}
          <div className="mt-auto">
            {/* Contact Icons */}
            <div className="flex justify-center gap-16">
              <a
                href="https://discord.gg/ZK9dv3cyvk"
                aria-label="Discord"
                className={contactClassname}
              >
                <FaDiscord className="w-8 h-8" />
              </a>
              <a
                href="mailto:shufflepolo@gmail.com"
                aria-label="Email"
                className={contactClassname}
              >
                <MdOutlineEmail className="w-8 h-8" />
              </a>
            </div>
            {isLoggedIn && (
              <button
                onClick={logout}
                className="w-full py-2 mt-6 mb-12 text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors shadow-md"
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Backdrop with Fade-In/Out */}
      <div
        onClick={toggleSidebar}
        className={`z-30 fixed inset-0 bg-black transition-opacity duration-500 ${backdropClass}`}
      ></div>
    </>
  );
};

export default Sidebar;
