import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Match from "./Match";
import gameTime from "../../../../functions/gameTime";
import { deleteField, doc, updateDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../../../firebase/config";
import { RiDeleteBinLine } from "react-icons/ri";
import { useTournament } from "../../TournamentContext";

const Day = ({
  tournamentId,
  dayId,
  numberOfDays,
  matchIds,
  updateMatchScore,
  startTime,
  gameLength,
  interval,
  numberOfCourts,
  scheduleType,
}) => {
  // const { matches } = useTournament();

  const { setNodeRef } = useDroppable({
    dayId,
  });

  // const deleteRound = async () => {
  //   const tournamentRef = doc(firestore, "tournaments", tournamentId);
  //   const schedulesRef = doc(firestore, "schedules", tournamentId);

  //   const schedulesBatch = writeBatch(firestore);

  //   for (const matchId of matchIds) {
  //     schedulesBatch.update(schedulesRef, { [matchId]: deleteField() });
  //   }

  //   try {
  //     await updateDoc(tournamentRef, { [`schedule.${dayId}`]: deleteField() });
  //   } catch (error) {
  //     alert(`Something went wrong: ${error}`);
  //   }

  //   try {
  //     await schedulesBatch.commit();
  //   } catch (error) {
  //     alert(`Something went wrong: ${error}`);
  //   }
  // };

  const title =
    scheduleType === "swissRounds"
      ? `Round ${Number(dayId) + 1}`
      : `Day ${Number(dayId) + 1}`;

  return (
    <div
      key={dayId}
      className="w-full max-w-lg bg-white shadow-xl rounded-xl overflow-hidden border border-stone-300 h-full"
    >
      <div className="bg-slate-400 p-3 relative">
        {/* {Number(dayId) === numberOfDays - 1 &&
          !matches[matchIds[0]].team1Score && (
            <RiDeleteBinLine className="w-5 h-5 absolute top-4 left-1 text-stone-600" />
          )} */}
        <h3 className="text-xl font-bold text-white text-center">{title}</h3>
      </div>

      <SortableContext
        id={dayId}
        items={matchIds}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef}>
          {matchIds.map((matchId, index) => {
            const displayGameTime = gameTime(
              index,
              startTime,
              gameLength,
              interval,
              numberOfCourts
            );

            return (
              <Match
                key={matchId}
                matchIndex={index}
                matchId={matchId}
                dayId={dayId}
                updateMatchScore={updateMatchScore}
                gameTime={displayGameTime}
                numberOfCourts={numberOfCourts}
                disabled={
                  scheduleType === "swissRounds" &&
                  Number(dayId) !== numberOfDays - 1
                }
              />
            );
          })}
        </div>
      </SortableContext>
    </div>
  );
};

export default Day;
