import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../../firebase/config";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  where,
} from "firebase/firestore";
import Loading from "../../components/loading/Loading.jsx";
import Tournament from "./Tournament.jsx";
import { query } from "firebase/database";

const TournamentContext = React.createContext();
const TournamentUpdateContext = React.createContext();

export const useTournament = () => {
  return useContext(TournamentContext);
};

export const useTournamentUpdate = () => {
  return useContext(TournamentUpdateContext);
};

const TournamentProvider = ({ user }) => {
  const { tournamentId } = useParams();
  const [isUserOrganizer, setIsUserOrganizer] = useState(undefined);
  const [tournament, setTournament] = useState(undefined);
  const [teams, setTeams] = useState(undefined);
  const [matches, setMatches] = useState(undefined);
  const [games, setGames] = useState(undefined);
  const [profiles, setProfiles] = useState(undefined);

  useEffect(() => {
    const isUserOrganizer = tournament?.organizerUids.includes(user?.uid);

    setIsUserOrganizer(isUserOrganizer);
  }, [tournament]);

  useEffect(() => {
    const fetchTournament = async () => {
      const tournamentRef = doc(firestore, "tournaments", tournamentId);

      try {
        const tournamentDoc = await getDoc(tournamentRef);
        setTournament(tournamentDoc.data());
      } catch (error) {
        console.log(error);
      }
    };

    const fetchTeams = async () => {
      const teamsRef = doc(firestore, "teams", tournamentId);

      try {
        const teamDoc = await getDoc(teamsRef);
        setTeams(teamDoc.data());
      } catch (error) {
        console.log(error);
      }
    };

    const fetchMatches = async () => {
      const matchesRef = doc(firestore, "schedules", tournamentId);

      try {
        const matchesDoc = await getDoc(matchesRef);
        setMatches(matchesDoc.data());
      } catch (error) {
        console.log(error);
      }
    };

    const fetchGames = async () => {
      const gamesRef = doc(firestore, "brackets", tournamentId);

      try {
        const gamesDoc = await getDoc(gamesRef);
        setGames(gamesDoc.data());
      } catch (error) {
        console.log(error);
      }
    };

    fetchTournament();
    fetchTeams();
    fetchMatches();
    fetchGames();
  }, [tournamentId]);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (!teams || !Object.keys(teams).length) {
        setProfiles({});
        return;
      }

      const profileIds = [];

      for (const teamData of Object.values(teams)) {
        if (!teamData?.profileIds) {
          return;
        }

        const uids = teamData.profileIds.filter(
          (profileId) => profileId.length === 28
        );
        profileIds.push(...uids);
      }

      if (!profileIds.length) {
        setProfiles({});
        return;
      }

      if (tournament.schedule) {
        const sessionStorageProfiles = sessionStorage.getItem("profiles");

        if (sessionStorageProfiles) {
          setProfiles(JSON.parse(sessionStorageProfiles));
          return;
        }
      }

      const profilesRef = collection(firestore, "profiles");

      const profilesQuery = query(
        profilesRef,
        where(documentId(), "in", profileIds)
      );

      try {
        const profiles = {};
        const profilesResponse = await getDocs(profilesQuery);

        if (profilesResponse.docs) {
          profilesResponse.docs.map(
            (profile) => (profiles[profile.id] = profile.data())
          );
          setProfiles(profiles);
          if (tournament.schedule) {
            sessionStorage.setItem("profiles", JSON.stringify(profiles));
          }
        } else {
          console.log("No profiles!");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProfiles();
  }, [teams]);

  const updateTournament = (section, object) => {
    setTournament((prev) => {
      return { ...prev, [section]: object };
    });
  };

  const updateTeams = (teamId, team) => {
    setTeams((prevTeams) => ({
      ...prevTeams,
      [teamId]: team,
    }));
  };

  const updateMatches = (matchId, match) => {
    setMatches((prevMatches) => ({
      ...prevMatches,
      [matchId]: match,
    }));
  };

  const updateGames = (gameId, game) => {
    setGames((prevGames) => ({
      ...prevGames,
      [gameId]: game,
    }));
  };

  const updateBracket = (section, object, bracketLevel) => {
    setTournament((prev) => {
      return { ...prev, [section]: { [bracketLevel]: object } };
    });
  };

  if (!tournament || (teams && !profiles) || isUserOrganizer === undefined) {
    return <Loading />;
  }

  return (
    <TournamentContext.Provider
      value={{
        isUserOrganizer,
        tournament,
        tournamentId,
        teams,
        matches,
        games,
        profiles,
      }}
    >
      <TournamentUpdateContext.Provider
        value={{
          updateTournament,
          updateTeams,
          updateMatches,
          updateGames,
          updateBracket,
        }}
      >
        <Tournament user={user} />
      </TournamentUpdateContext.Provider>
    </TournamentContext.Provider>
  );
};

export default TournamentProvider;
