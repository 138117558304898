import React, { useState } from "react";
import SectionTitle from "../../../components/SectionTitle";
import CreateSchedule from "./components/CreateSchedule";
import { useTournament, useTournamentUpdate } from "../TournamentContext";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  onDragEnd,
  onDragOver,
  onDragStart,
} from "../../../functions/dragAndDrop";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Day from "./components/Day";
import SaveSchedule from "./components/SaveSchedule";
import Match from "./components/Match";
import deleteSchedule from "../../../functions/deleteSection/deleteSchedule";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import ScheduleTimes from "./components/ScheduleTimes";
import Referees from "./components/Referees";
import Standings from "../standings/Standings";
import GenerateNextSwissRound from "./components/GenerateNextSwissRound";
import { onDragEndDebounceDelay } from "../../../constants";
import Break from "./components/Break";

const Schedule = () => {
  const { tournament, tournamentId, matches, teams, isUserOrganizer } =
    useTournament();
  const { updateTournament } = useTournamentUpdate();
  const { groups, schedule, isSchedulePublic, scheduleTimes, scheduleType } =
    tournament;

  const [items, setItems] = useState(schedule);
  const [activeId, setActiveId] = useState();
  const [matchUpdates, setMatchUpdates] = useState({});

  const updateMatchScore = (matchId, key, score) => {
    setMatchUpdates((prev) => ({
      ...prev,
      [matchId]: {
        ...prev[matchId],
        [key]: score,
      },
    }));
  };

  const handleSetMatchUpdates = (value) => {
    setMatchUpdates(value);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      delay: 300,
    })
  );

  const handleSetItems = (value) => {
    setItems(value);
  };

  const handleSetActiveId = (value) => {
    setActiveId(value);
  };

  const handleDragStart = (event) => {
    onDragStart(event, items, handleSetActiveId);
  };

  const handleDragOver = (event) => {
    onDragOver(event, items, handleSetItems, true);
  };

  const handleDragEnd = (event) => {
    const { newItems } = onDragEnd(
      event,
      items,
      handleSetItems,
      handleSetActiveId
    );
    updateTournament("schedule", newItems);

    const writeScheduleUpdate = async () => {
      try {
        const tournamentRef = doc(firestore, "tournaments", tournamentId);
        await updateDoc(tournamentRef, { schedule: newItems });
      } catch (error) {
        console.log(error.message);
        alert(error.message);
      }
    };

    const handler = setTimeout(() => {
      writeScheduleUpdate();
    }, onDragEndDebounceDelay);

    return () => clearTimeout(handler);
  };

  const handleDeleteSchedule = () => {
    deleteSchedule(tournamentId, teams);
  };

  const togglePublicity = async () => {
    const tournamentRef = doc(firestore, "tournaments", tournamentId);

    updateTournament("isSchedulePublic", !isSchedulePublic);

    try {
      await updateDoc(tournamentRef, { isSchedulePublic: !isSchedulePublic });
    } catch (error) {
      alert("Something went wrong updating the visibility!");
      console.log(error);
    }
  };

  if (!groups || !teams || (!isSchedulePublic && !isUserOrganizer)) {
    return;
  }

  return (
    <section className="w-full flex flex-col items-center mb-6">
      <SectionTitle
        title={"Schedule"}
        onDelete={schedule ? handleDeleteSchedule : null}
        isPublic={isSchedulePublic}
        handlePublicity={togglePublicity}
      />

      {items && matches ? (
        <>
          {isUserOrganizer && (
            <>
              <SaveSchedule
                matchUpdates={matchUpdates}
                handleSetMatchUpdates={handleSetMatchUpdates}
                tournamentId={tournamentId}
              />
              <div className="w-full flex justify-center gap-4 mb-4 ">
                <Break schedule={schedule} tournamentId={tournamentId} />

                <ScheduleTimes
                  numberOfDays={Object.keys(items).length}
                  tournamentStartTimes={scheduleTimes.startTimes}
                  tournamentGameLength={scheduleTimes.gameLength}
                  tournamentInterval={scheduleTimes.interval}
                  tournamentNumberOfCourts={scheduleTimes.numberOfCourts}
                  tournamentId={tournamentId}
                />

                <Referees
                  schedule={tournament.schedule}
                  matches={matches}
                  teams={teams}
                  tournamentId={tournamentId}
                />
              </div>
            </>
          )}

          <Standings />

          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <div className="w-full flex flex-wrap justify-center gap-4 mt-6">
              {Object.keys(items)
                .sort()
                .map((dayId, index) => {
                  const matchIds = items[dayId];
                  return (
                    <Day
                      key={dayId}
                      tournamentId={tournamentId}
                      dayId={dayId}
                      numberOfDays={Object.keys(items).length}
                      matchIds={matchIds}
                      updateMatchScore={updateMatchScore}
                      startTime={scheduleTimes.startTimes[index]}
                      gameLength={scheduleTimes.gameLength}
                      interval={scheduleTimes.interval}
                      numberOfCourts={scheduleTimes.numberOfCourts}
                      scheduleType={scheduleType}
                    />
                  );
                })}

              <DragOverlay>
                {activeId ? <Match matchId={activeId} /> : null}
              </DragOverlay>
            </div>
          </DndContext>
        </>
      ) : (
        <CreateSchedule />
      )}

      {scheduleType === "swissRounds" && schedule && isUserOrganizer && (
        <GenerateNextSwissRound
          teams={teams}
          matches={matches}
          schedule={schedule}
          scheduleTimes={scheduleTimes}
          tournamentId={tournamentId}
        />
      )}
    </section>
  );
};

export default Schedule;
