import React, { useEffect, useState, useRef } from "react";
import { firestore } from "../../../firebase/config";
import { collection, getDocs, limit, query, where } from "firebase/firestore";

const PlayerSearch = ({
  index,
  handleProfiles,
  defaultSearchTerm,
  showTitle,
  handleSelected,
}) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const [profiles, setProfiles] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(undefined);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchProfiles = async (text) => {
      if (
        selectedProfile ||
        text?.length < 3 ||
        searchTerm === defaultSearchTerm
      ) {
        return;
      }

      if (!text) {
        setProfiles([]);
        setDropdownOpen(false);
        setSelectedProfile(undefined);
        return;
      }

      try {
        const profilesRef = collection(firestore, "profiles");
        const q = query(
          profilesRef,
          where("name", ">=", text?.trim()),
          where("name", "<=", text?.trim() + "\uf8ff"),
          limit(3)
        );

        const querySnapshot = await getDocs(q);

        const results = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProfiles(results);
        setDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    const handler = setTimeout(() => {
      const debouncedSearchTerm = searchTerm?.trim().toLocaleLowerCase();
      fetchProfiles(debouncedSearchTerm);
    }, 600);

    return () => clearTimeout(handler);
  }, [searchTerm, selectedProfile]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Reset the selected profile if the user starts typing
    if (selectedProfile) {
      setSelectedProfile(undefined);
    }
    handleProfiles(index, event.target.value);
  };

  const handleProfileClick = (event, profile) => {
    event.preventDefault();
    setSelectedProfile(profile);
    setSearchTerm(profile.name); // Update the input field to show the selected profile's name
    setDropdownOpen(false);
    handleProfiles(index, profile.id);

    if (handleSelected) {
      handleSelected(true);
    }
  };

  const handleBlur = (event) => {
    // Delay closing to allow clicks on dropdown items
    setTimeout(() => {
      if (!dropdownRef.current?.contains(event.relatedTarget)) {
        setDropdownOpen(false);
      }
    }, 150);
  };

  return (
    <div className="relative w-full max-w-md mx-auto my-2">
      {showTitle && <label>Player {index + 1}</label>}

      <input
        type="text"
        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search profiles by name"
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => {
          if (searchTerm) {
            setDropdownOpen(true);
          }
        }}
        onBlur={handleBlur}
      />

      {dropdownOpen && profiles?.length > 0 && (
        <ul
          ref={dropdownRef}
          className="absolute z-10 w-full bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto"
        >
          {profiles.map((profile) => (
            <li
              key={profile.id}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={(event) => handleProfileClick(event, profile)}
            >
              <div className="text-sm font-medium">{profile.name}</div>
              {profile.club && (
                <div className="text-xs text-gray-500">{profile.club}</div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PlayerSearch;
